
import refetsToWinBanner from './assets/refers-to-win.png';
import cashbackAfterlost from './assets/cashbak-after-lost.png';
import welcomeBonusBanner from './assets/welcome-bonus.png';
import dailyLoginBanner from './assets/daily-login-bonus.jpeg';

type BonusItem = {
  id: string;
  title: string;
  description: string;
  preview: string;
  content: string;
};

export const bonuses: BonusItem[] = [
  {
    id: 'welcome-bonus',
    title: 'Welcome Bonus',
    description: 'Welcome to Cheza bet karibu bonus. Deposit up to 5,000 and get a matching bonus equivalent to your first deposit',
    preview: welcomeBonusBanner,
    content: `
      <h1>Welcome Bonus</h1>
      <p><img src=${welcomeBonusBanner} /></p>
      <p>
        Welcome to Cheza bet karibu bonus. Deposit up to 5,000 and get a matching bonus equivalent to your first deposit
      </p>
      <p><b>RULES:</b></p>
      <ul>
        <li>1.	The player receives a bonus equal to 100% of their initial deposit up to Kes 5,000.</li>
        <li>2.	The bonus will be credited to your sports book player account.</li>
        <li>3.	9.99 with a maximum of 100 odd is the maximum odd you can stake with the bonus.</li>
        <li>4.	For the bonus to qualify place a multi bet on pre-games played.</li>
        <li>5.	Cash-outs and live games are not applicable on the bonus.</li>
      </ul>
      <p><b>DEPOSITS UP TO 5,000 GET 100% WELCOME BONUS, WIN MORE WITH CHEZA BET.</b></p>
    `,
  },
  {
    id: 'cashbacl-after-lost-bonus',
    title: 'CASHBACK AFTER A LOST BET',
    description: 'Cheza bet rewards our customers a 10% cashback when they lose their bet.',
    preview: cashbackAfterlost,
    content: `
      <h1>CASHBACK AFTER A LOST BET</h1>
      <p><img src=${cashbackAfterlost} /></p>
      <p>
        Cheza bet rewards our customers a 10% cashback when they lose their bet.
      </p>
      <p><b>RULES:</b></p>
      <ul>
        <li>1.	Get a 10% Cash Back on your lost bets. </li>
        <li>2.	Bet on at least 5,000 weekly to wager bonus.</li>
        <li>3.	Losing bets must be settled at odds of 1.5 or higher.</li>
        <li>4.	When calculating the value of the bonus, totals and handicaps will not be calculated.</li>
        <li>5.	Bets placed at 1.00 odd , cashed out bets, bets placed with promo codes and bonus funds are not included in this offer.</li>
        <li>6.	Cheza bet reserves the right to review the customers record and logs for verification. The company reserves to revoke the customers entitlement to participate in the bonus program if found with any suspicious activities.</li>
        <li>7.	Only one bonus is allowed per player.</li>
        <li>8.	Place bets within seven days of registration .</li>
      </ul>
      <p>Cheza bet has the right to amend the terms of the offer, cancel or renew the offer, or refuse participation at any time without prior notice.</p>
    `,
  },
  {
    id: 'refer-to-win-bonus',
    title: 'REFER TO WIN',
    description: 'Earn up to 10% commission on each referral you bring in to chezabet.',
    preview: refetsToWinBanner,
    content: `
      <h1>REFER TO WIN</h1>
      <p><img src=${refetsToWinBanner} /></p>
      <p>
          Earn up to 10% commission on each referral you bring in to chezabet.
        </p>
        <p>Win more as your friends play!</p>
    `,
  },
  {
    id: 'daily-login-bonus',
    title: 'DAILY LOGIN BONUS',
    description: 'Get Chezabet FREE BET! Omoka easy with our free bet. Use "freebet" as promo code! Play now!',
    preview: dailyLoginBanner,
    content: `
      <h1>DAILY LOGIN BONUS</h1>
      <p><img src=${dailyLoginBanner} /></p>
      <ul>
        <li><b>1. General</b>
          <ul>
            <li>1.1. <b>Daily login bonus</b> ("Promotion") is organized by <b>Chezabet.ke</b>. </li>
            <li>1.2. By participating in this promotion, players agree to abide by these Terms & Conditions.</li>
            <li>1.3. The promotion runs everyday <b>(local time)</b>.</li>
          </ul>
        </li>
        <li><b>2. Eligibility</b>
          <ul>
            <li>2.1. The promotion is open to all registered <b>Chezabet.ke</b> players aged <b>18 years and above</b>. </li>
            <li>2.2. Only verified accounts with a valid phone number.</li>
          </ul>
        </li>
        <li><b>3. Promotion Details </b>
          <ul>
            <li>3.1. The daily <b>login bonus</b> offers players a <b>bonus ranging from KES 10 to KES 10,000</b> during the promotional period.</li>
            <li>3.2. To claim the <b>daily login Bonus</b>, players must use the promo code indicated on the site.</li>
            <li>3.3. <b>The bonus is credited instantly</b> after the promo code is applied.</li>
            <li>3.4. After claiming the bonus, the punter must make a deposit <b>equal to the awarded bonus</b> before placing bets.</li>
            <li>3.5. <b>The maximum winnings from the bonus are capped at KES 20,000.</b> Any amount exceeding this limit will not be credited.</li>
          </ul>
        </li>
        <li><b>4. Bonus Wagering Requirements</b>
          <ul>
            <li>4.1. The awarded bonus must be placed in full on a multi-bet of at least 5games with cumulative odds of 9.99 or higher.</li>
            <li>4.2. Each selection within the multi-bet must have minimum odds of 1.5 or more.</li>
            <li>4.3. Immediately after claiming the bonus, the punter must deposit the amount equal to the awarded bonus and place a bet with minimum odds of 2.00 with the deposit, before using the bonus claimed.</li>
            <li>4.4. If a punter places bets using only the bonus without making the required deposit, the bet will go through. However, any winnings will remain in the bonus account. </li>
            <li>4.5. If a punter divides the bonus and places multiple bets, the winnings will count but will remain in the bonus account.</li>
            <li>4.6. The new winnings become the new baseline, and the punter must deposit an amount equal to the new winnings to unlock them. </li>
            <li>4.7. A deposit made after winning will NOT count toward unlocking the winnings. The required deposit must be made immediately after claiming the bonus. </li>
            <li>4.8. The initial wagering requirements remain in effect, even after winnings are generated from divided bonus bets. </li>
            <li>4.9. Any misuse of the bonus (bonus arbitrage) especially on two-way markets will forfeit the winnings immediately. </li>
          </ul>
        </li>
        <li><b>5. Deposit Winnings & Withdrawals</b>
          <ul>
            <li>5.1. <b>Deposit winnings are NOT tied to the bonus and can be withdrawn instantly.</b></li>
            <li>5.2. Any winnings from the bonus will be credited to the player's main account after meeting all wagering requirements. </li>
          </ul>
        </li>
        <li><b>6. Fraud & Abuse</b>
          <ul>
            <li>6.1. <b>Chezabet.ke</b> reserves the right to cancel or revoke bonuses in case of suspected fraudulent activities, multiple accounts, or abuse of the promotion. </li>
            <li>6.2. Accounts found in violation of these terms will be suspended or permanently banned.</li>
          </ul>
        </li>
        <li><b>7. Amendments & Termination</b>
          <ul>
            <li>7.1. <b>Chezabet.ke</b> reserves the right to modify, suspend, or terminate the promotion at any time without prior notice. </li>
            <li>7.2. Any changes will be communicated via the official website and social media channels.</li>
          </ul>
        </li>
      </ul>
    `,
  },
];

export const bonusesUA: BonusItem[] = [];

export const bonusesEN: BonusItem[] = [];

export const bonusessListByLang: Record<string, BonusItem[]> = {
  default: bonuses,
  'en': bonuses,
};
