import { bemCn } from '@shared/utils/helpers/bem-cn';
import { NavLink } from 'react-router-dom';

import { getBasketCount, useGetBasketQuery } from '@/modules/basket';
import { useDispatchedActions, useAppSelector } from '@shared/hooks';
import IconSvg, { AvailableIcon } from '@/shared/components/icon-svg/icon-svg';
import { getIsAuth } from '@/modules/auth';
import { useEffect, useRef } from 'react';
import { useResponsive } from '@/shared/hooks/use-responsive';
import { Trans, useTranslation } from 'react-i18next';
import { scrollToTop } from '@/shared/utils/helpers/common';
import { AppFeature } from '@/app-config';
import './nav-bar.scss';

type NavBarItem = {
  id: string;
  title: string;
  link: string;
  counter?: number;
  icon: AvailableIcon;
  onClick?: (evt: React.MouseEvent) => void;
  disabled?: boolean;
}

const items: NavBarItem[] = [
  {
    id: 'home',
    title: 'Главная',
    link: '/',
    icon: 'home',
  },
  {
    id: 'live',
    title: 'Live',
    link: '/live',
    icon: 'live',
    disabled: AppFeature.DISABLE_SPORTBOOK,
  },
  {
    id: 'cart',
    title: 'Корзина',
    link: '',
    icon: 'cart',
    disabled: AppFeature.DISABLE_SPORTBOOK,
  },
  {
    id: 'line',
    title: 'Линия',
    link: '/line',
    icon: 'football',
    disabled: AppFeature.KES_PROJECT || AppFeature.DISABLE_SPORTBOOK,
  },
  {
    id: 'casino',
    title: 'Казино',
    link: '/games',
    icon: 'coin',
    disabled: AppFeature.KES_PROJECT && !AppFeature.CASINO_ONLY,
  },
  {
    id: 'crash-games',
    title: 'Crash Games',
    link: '/crash-games',
    icon: 'rocket',
    disabled: !AppFeature.CASINO_ONLY,
  },
  {
    id: 'bet-history',
    title: 'Bet History',
    link: '/bets-history',
    icon: 'desktop',
    disabled: !AppFeature.KES_PROJECT || AppFeature.DISABLE_SPORTBOOK,
  },
  {
    id: 'profile',
    title: 'Профиль',
    link: '/profile',
    icon: 'user',
    disabled: !AppFeature.KES_PROJECT,
  },
];

type NavBarProps = {
  className?: string;
}

const b = bemCn('nav-bar');

const NavBar = ({ className }: NavBarProps) => {
  const { isMobile } = useResponsive();
  const navRef = useRef<HTMLDivElement>(null);
  const { changeAppModal, changeAuthModal } = useDispatchedActions();
  const isAuth = useAppSelector(getIsAuth);
  useGetBasketQuery(undefined, { skip: !isAuth || AppFeature.DISABLE_SPORTBOOK });
  const basketCount = useAppSelector(getBasketCount);
  const { t } = useTranslation('', { keyPrefix: 'main-nav' });

  const handleItemClick = (itemId: string, e: React.MouseEvent) => {
    switch (itemId) {
      case 'cart':
        e.preventDefault();
        return isAuth ? changeAppModal('basket') : changeAuthModal('login');
      case 'profile':
      case 'bet-history':
        if (!isAuth) {
          e.preventDefault();
          return changeAuthModal('login');
        }
        return scrollToTop(true);
      default:
        return scrollToTop(true);
    }
  };

  useEffect(() => {
    const footerElement = document.getElementById('page-footer');
    if (footerElement && navRef.current && isMobile) {
      footerElement.style.paddingBottom = `${navRef.current.offsetHeight}px`;
    }

    return () => {
      if (footerElement) {
        footerElement.style.paddingBottom = '';
      }
    };
  }, [navRef, isMobile]);

  return (
    <div className={b({ 'grid-4': AppFeature.CASINO_ONLY }, className)} ref={navRef}>
      <ul className={b('list')}>
        {items.filter((item) => !item.disabled)
          .map(({ id, title, link, icon }) => (
            <li className={b('item')} key={id}>
              <NavLink
                className={({ isActive }) => b('link', { active: isActive && id !== 'cart' })}
                to={link}
                onClick={(evt) => handleItemClick(id, evt)}
              >
                <span className={b('icon-wrapper')}>
                  <IconSvg className={b('icon')} name={icon} />
                </span>
                <span className={b('label')}>
                  <Trans t={t} i18nKey={id}>{title}</Trans>
                </span>
                {id === 'cart' && !!basketCount ? (
                  <span className={b('counter')}>{basketCount}</span>
                ) : null}
              </NavLink>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default NavBar;
